.logo:visited {
     color: #bbbbbb;
 }

.logo:hover {
    color: #bbbbbb;
    text-decoration: none;
}

.logo {
    color: #bbbbbb;
}

.profile {
  color: #bbbbbb;
  line-height: 32px;
}