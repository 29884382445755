.todo-list-item {
    font-size: 1.25rem;
}

.todo-list-item button {
    /*width: 50px;*/
    margin: 3px;
    font-weight: bold;
}

.todo-list-item button.on {
   /* background-color: lightgreen; */
    color:#3d9970;
}

.todo-list-item button.off {
    /* background-color:#ababab ;*/
    color: #ababab;
}

.todo-list-item-label {
    margin-left: 1.25rem;
    line-height: 35px;
    cursor: pointer;
    user-select: none;
}

.todo-list-item.done .todo-list-item-label {
    text-decoration: line-through;
}

.todo-list-item.important .todo-list-item-label {
    font-weight: bold;
    color: steelblue;
}

.gameLevel {
    margin-left: 5px;
    margin-right: 5px;
}
